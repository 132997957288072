<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="Upload Certis Report">
      <asom-input-text type="file" id="fileInput" ref="fileInput" @change="onFileUpload" />
      <template #footer>
        <div class="flex justify-end flex-1">
          <asom-button text="Validate" @click="validateFile" :loading="isValidating" :disabled="isValidating || formData.file === null" />
        </div>
      </template>
    </asom-card>
    <collection-summary-validation-result 
      v-if="isValidationCompleted && !error"
      :errors="validationResults.errors"
      :isSubmitting="isSubmitting"
      @submit="onSubmit"
    >
      <asom-client-table
        v-if="validationResults.errors.length === 0"
        :data="validationResults.data"
        :columns="columns">
        <template v-slot:collectionDate="{data}">{{displayUtcDate(data)}}</template>
        <template v-slot:generatedDate="{data}">{{displayUtcDate(data)}}</template>
      </asom-client-table>
    </collection-summary-validation-result>
  </div>
</template>
<script>
import get from 'lodash.get';
import { displayUtcDate } from '@/helpers/dateTimeHelpers'
import { uploadCertisReport, validateCertisReport } from '../../../../services/cashManagement.service';
import CollectionSummaryValidationResult from './_CollectionSummaryValidationResult.vue'
import { uploadFile } from '../../../../services/file.service';
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  components: {
    CollectionSummaryValidationResult
  },
  data() {
    return {
      formData: {
        file: null,
      },
      isValidating: false,
      isSubmitting: false,
      isValidationCompleted: false,
      error: null,
      validationResults: {
        errors: [],
        data: []
      },
    }
  },
  computed: {
    columns() {
      return ["transactionSource", "generatedDate", "collectionDate", "stationAbbreviation", "ciscoSealNumber", "cashBoxNumber", "verifiedValue", "totalValue", "remarks"]
    }
  },
  methods: {
    displayUtcDate,
    onFileUpload(event) {
      this.formData.file = event.target.files[0]
    },
    async validateFile() {
      if (this.formData.file === null)
        return;
      this.isValidating = true;
      this.error = null;
      this.validationResults.errors = [];
      this.validationResults.data = [];
      this.isValidationCompleted = false;
      const result = await validateCertisReport([ this.formData.file ]);
      if (result.success) {
        this.validationResults.errors = get(result, 'payload.errorList', []);
        this.validationResults.data = get(result, 'payload.data', []);
      } else {
        this.error = result.payload;
      }
      
      this.isValidating = false;
      this.isValidationCompleted = true;
    },
    async onSubmit() {
      this.error = null;
      this.isValidating = true;
      this.isSubmitting = true;
      if (this.formData.file === null) {
        this.error = 'Please select a file to upload'
      } else {
        const resp1 = await uploadFile([ this.formData.file ], ATTACHMENT_CATEGORIES.CASH_MANAGEMENT);
        if (resp1.success) {
          const fileId = get(resp1, 'payload.files.0.fileId');
          const resp2 = await uploadCertisReport({
            attachmentId: fileId,
            transactions: this.validationResults.data.map(item => ({
              ...item,
              isNotesColl: item.transactionSource === "Note Box",
              isCoinsColl: item.transactionSource === "Coin Box",
              isMC: item.transactionSource === "MC",
              isPSM: item.transactionSource === "PSM",
              isGTMCoinTube: item.transactionSource === "GTM Coin Tube",
            }))
          });
          if (!resp2.success) {
            this.error = resp2.payload;
          } else {
            this.$router.push({ name : 'Cash Collection Summary Main Page' });
          }
        } else {
          this.error = resp1.payload;
        }
      }
      this.isValidating = false;
      this.isSubmitting = false;
    }
  }
}
</script>